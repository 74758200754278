import PersonalDetailBox from '../PersonalDetailBox';
import Savings from '../order/Savings';
import LeadGenerationBanner from '../LeadGenerationBanner';
import { Order } from 'types/types';
import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import SideBar from '../SideBar';

export function AccountOrderSideBar({
  savings,
  order,
}: {
  savings: number | null;
  order: Order | null;
}) {
  const router = useRouter();
  const { t } = useTranslation();

  return (
    <SideBar>
      <PersonalDetailBox
        title={t('app.ui.my_information')}
        onClickEdit={() => router.push(`/account/edit`)}
      />
      <Savings savings={savings} />
      {order && (
        <LeadGenerationBanner position={'dashboard'} orderId={order.id} orderValue={order.total} />
      )}
    </SideBar>
  );
}

export default AccountOrderSideBar;
