import { Address } from '../../types/types';

export default function AddressBlock({ address }: { address: Address }) {
  return (
    <div data-testid={'addressBlock'}>
      <div>
        {address.firstName} {address.lastName}
      </div>
      <div>
        {address.street} {address.houseNumber} {address.houseNumberExtension}
      </div>
      <div>
        {address.postalCode} {address.city}
      </div>
    </div>
  );
}
