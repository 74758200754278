import { useTranslation } from 'react-i18next';
import useStore from 'store';
import useSession from '../hooks/useSession';
import AddressBlock from './address/AddressBlock';
import React from 'react';
import Box from './order/box/Box';

const PersonalDetailBox = ({
  title,
  onClickEdit,
  renderCustomFooter,
}: {
  title: string;
  onClickEdit: () => void;
  renderCustomFooter?: () => JSX.Element;
}) => {
  const { session } = useSession();
  const { t } = useTranslation();
  const [customer] = useStore('customer');
  if (!session || !customer) {
    return null;
  }

  return (
    <Box>
      <div className="flex items-center justify-between p-2 row">
        <h2 className="font-bold">{title}</h2>
        <div onClick={onClickEdit} className="underline cursor-pointer text-primary">
          {t('app.ui.edit')}
        </div>
      </div>

      <div className="flex-col p-2">
        <p className="pb-4">{customer.email}</p>
        {customer?.defaultAddress ? (
          <AddressBlock address={customer.defaultAddress} />
        ) : (
          <div className="mb-4">
            {customer.firstName} {customer.lastName}
          </div>
        )}
      </div>

      {renderCustomFooter ? <div className="flex-col p-5">{renderCustomFooter()}</div> : null}
    </Box>
  );
};

export default PersonalDetailBox;
