import { useEffect, useId, useRef } from 'react';
import config from 'next/config';
import { SovendusIframeProps } from 'types/sovendus';
import { trackSovendusBannerClick } from 'modules/tracking/events/events';
import useStore from 'store';
import useSession from 'hooks/useSession';
import ExternalJavascript from 'components/ExternalJavascript/ExternalJavascript';

export default function LeadGenerationBanner({
  position,
  orderId,
  orderValue,
}: {
  position: 'dashboard' | 'thank-you';
  orderId: number;
  orderValue: number;
}) {
  const executed = useRef(false);

  const [customer] = useStore('customer');
  const { session, status } = useSession();
  const { publicRuntimeConfig } = config();
  const iframeContainerId = `sovendus-iframe-${position}-${useId().replaceAll(':', '-')}`;
  const trafficSourceNumber = publicRuntimeConfig.sovendus.trafficSourceNumber;
  const trafficMediumNumber =
    position === 'dashboard'
      ? publicRuntimeConfig.sovendus.trafficMediumNumberDashboard
      : publicRuntimeConfig.sovendus.trafficMediumNumberThankYouPage;
  const scriptFile = publicRuntimeConfig.sovendus.scriptFile;
  const orderCurrency = 'EUR';

  async function trackClick() {
    // This is not working, to be revised in AVDD-7448
    await trackSovendusBannerClick({ position });
  }

  useEffect(() => {
    if (executed.current) {
      return;
    }

    // this adds the iframe only when the session is loaded and the customer details are known.
    if (status === 'loading' || !session) {
      return;
    }

    const iframes: SovendusIframeProps[] =
      ((window as any).sovIframe as SovendusIframeProps[]) || [];

    // This prevents the iframe from being added multiple times
    if (
      iframes.filter(
        (iframe) => iframe.orderId === orderId && iframe.iframeContainerId === iframeContainerId
      ).length > 0
    ) {
      return;
    }

    const now = new Date().getTime();

    const iframe: SovendusIframeProps = {
      trafficSourceNumber,
      trafficMediumNumber,
      sessionId: session.token,
      orderId,
      orderValue: orderValue / 100,
      orderCurrency,
      timestamp: now,
      iframeContainerId,
    };

    (window as any).sovIframes = iframes;
    (window as any).sovIframes.push(iframe);

    (window as any).sovConsumer = {
      consumerSalutation: '',
      consumerFirstName: customer?.firstName ?? '',
      consumerLastName: customer?.lastName ?? '',
      consumerEmail: customer?.email ?? '',
    };

    executed.current = true;
  }, [
    iframeContainerId,
    orderId,
    status,
    session,
    customer,
    trafficSourceNumber,
    trafficMediumNumber,
    orderValue,
    scriptFile,
  ]);

  return (
    <>
      <div id={iframeContainerId} onClick={trackClick} data-testid="sovendus-iframe-container" />
      <ExternalJavascript src={scriptFile} />
    </>
  );
}
