import { Address, Customer } from 'types/types';
import config from 'next/config';
import { doFetch, resolveApiUrl } from 'lib/api';
import { captureTypeErrorIfInvalid } from 'lib/error';
import API_ENDPOINTS from 'constants/routes/api';

export default async function updateDefaultAddress(
  token: string,
  address: Address,
  customer: Customer | null
): Promise<Address> {
  const { publicRuntimeConfig } = config();
  const endpoint = resolveApiUrl(API_ENDPOINTS.UpdateDefaultAddress(customer!.customerId));
  const res = await doFetch(endpoint, {
    body: JSON.stringify({ defaultAddress: address }),
    headers: {
      'X-AUTH-TOKEN': token,
      accept: 'application/json',
      'Content-Type': 'application/merge-patch+json',
    },
    method: 'PATCH',
    redirect: 'follow',
    mode: 'cors',
  });

  if (!res.ok) {
    throw new Error();
  }

  const result = await res.json();

  captureTypeErrorIfInvalid('Address', result.defaultAddress);

  return result.defaultAddress;
}
