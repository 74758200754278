import { Navbar } from './navbar/NavBar';
import { Container } from '@mui/material';
import { MenuHorizontal } from './navbar/MenuHorizontal';
import { Taxon } from 'types/types';
import { DefaultHead } from './DefaultHead';
import { AccountMenu } from './menu/AccountMenu';
import { useResponsive } from 'hooks/useResponsive';
import { PreFooter } from './PreFooter';
import { Footer } from 'components/footer/Footer';
import { ReactNode } from 'react';
import { NewsLetterInFooter } from 'components/newsletter/NewsletterInFooter';
import { useNewsletter } from 'hooks/newsletter/useNewsletter';
import { useAppSelector } from 'redux/appStore';
import { selectTextBanner } from 'redux/common/common.slice';
import { TextBanner } from 'components/Banner/TextBanner';

interface Props {
  children: any;
  taxons: Taxon[];
  enableSearch?: boolean;
  showUsps?: boolean;
  showNewsletter?: boolean;
  newsletterComponent?: ReactNode;
  enableMobileMenu?: boolean;
  headerComponent?: ReactNode;
  rightSideBarComponent?: ReactNode;
  title?: string;
  sidebarMenu?: ReactNode;
  preFooter?: ReactNode;
}

export function AccountLayout({
  children,
  taxons,
  title,
  enableSearch = true,
  showUsps = true,
  newsletterComponent = <NewsLetterInFooter />,
  enableMobileMenu = true,
  headerComponent,
  rightSideBarComponent,
  sidebarMenu = <AccountMenu />,
  preFooter = <PreFooter />,
}: Props) {
  const { isXl } = useResponsive();
  const mainContentClassName = `lg:col-span-${rightSideBarComponent ? 6 : 10}`;
  const textBanner = useAppSelector(selectTextBanner);

  const { isCustomerSubscribed } = useNewsletter();

  return (
    <Container maxWidth={false} disableGutters={true} className="bg-bg-grey">
      <DefaultHead title={title} hasTitleSuffix={false} />
      <Navbar
        isHomepage={false}
        taxons={taxons}
        showAccount={false}
        showSearch={enableSearch}
        sidebarMenu={sidebarMenu}
        showUsps={showUsps}
        enableMobileMenu={enableMobileMenu}
      />
      <div suppressHydrationWarning className="min-h-full bg-bg-grey">
        <div className="bg-white shadow">
          <MenuHorizontal taxons={taxons} />
        </div>
      </div>
      {!!textBanner && (
        <div className={'container'}>
          <TextBanner banner={textBanner} />
        </div>
      )}
      <div className="container grid grid-cols-1 space-y-2 md:grid-cols-12 md:gap-4 xl:grid-cols-16 bg-bg-grey xl:gap-4">
        {headerComponent && (
          <div className="col-span-12 xl:col-span-12 xl:col-start-4">{headerComponent}</div>
        )}
      </div>
      <div className="container lg:grid lg:grid-cols-12 flex gap-4 sm:flex-row flex-col">
        {isXl && <div className="lg:col-span-2">{sidebarMenu}</div>}

        <div className={`grow ${mainContentClassName}`}>{children}</div>

        {rightSideBarComponent && (
          <div className="mb-10 lg:col-span-4">{rightSideBarComponent}</div>
        )}
      </div>
      {!isCustomerSubscribed && newsletterComponent}
      {preFooter}
      <Footer />
    </Container>
  );
}
