import fetchSavings from 'api/customer/fetchSavings';
import updateDefaultAddress from 'api/customer/updateDefaultAddress';
import fetchApiGeolocationFallback from 'api/customer/fetchApiGeolocationFallback';

const customerApi = {
  fetchSavings,
  updateDefaultAddress,
  fetchApiGeolocationFallback,
};

export default customerApi;
