import { doFetch, resolveApiUrl } from 'lib/api';
import API_ENDPOINTS from 'constants/routes/api';

export default async function fetchSavings(token: string): Promise<number> {
  const endpoint = resolveApiUrl(API_ENDPOINTS.GetSavings);

  const res = await doFetch(endpoint, {
    headers: {
      'X-AUTH-TOKEN': token,
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'GET',
    redirect: 'follow',
    mode: 'cors',
  });

  if (!res.ok) {
    throw new Error();
  }

  const result = await res.json();

  return result.savings;
}
