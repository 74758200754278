import { formatPrice } from 'lib/price';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { PLACEHOLDER_TRANS_TEXT } from 'constants/common';

export default function Savings({ savings }: { savings: null | number }) {
  const { t } = useTranslation();
  if (savings === null) {
    return null;
  }

  if (savings === 0) {
    return (
      <div className="text-xl mb-4 text-center w-full px-4 py-3 md:py-6 font-medium text-white border border-transparent rounded-md shadow-sm bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        <Trans i18nKey={'app.ui.no_savings_yet'}>
          {PLACEHOLDER_TRANS_TEXT}
          <span className="font-bold savings-amount">{PLACEHOLDER_TRANS_TEXT}</span>
          {PLACEHOLDER_TRANS_TEXT}
        </Trans>
      </div>
    );
  }

  return (
    <div className="text-xl mb-4 text-center w-full px-4 py-3 md:py-6 font-medium text-white border border-transparent rounded-md shadow-sm bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
      {t('app.ui.savings_amount_already')}&nbsp;
      <span className="font-bold savings-amount">
        {formatPrice(savings, { removeWhiteSpace: true })}
      </span>
      &nbsp;
      {t('app.ui.savings_amount_saved')}
    </div>
  );
}
