import { Coordinates } from 'types/geolocation';
import { doFetch, resolveApiUrl } from 'lib/api';
import API_ENDPOINTS from 'constants/routes/api';
import { AUTH_TOKEN_HEADER } from 'constants/api';
import { transformUserCoordinatesJson } from 'lib/transforms';

export async function fetchApiGeolocationFallback({
  userToken,
  ip,
}: {
  userToken?: string;
  ip?: string;
}): Promise<Coordinates> {
  const endpoint = resolveApiUrl(API_ENDPOINTS.GetUserLocationFallback);

  let headers: any = {};
  if (userToken) {
    headers[AUTH_TOKEN_HEADER as keyof typeof headers] = userToken;
  }

  if (ip) {
    headers['X-Forwarded-For' as keyof typeof headers] = ip;
  }

  const options = Object.keys(headers).length ? { headers } : {};
  const res = await doFetch(endpoint, options).catch((e) => {
    console.warn('Failed to fetch user location fallback', e.message);
    return e;
  });

  if (!res.ok) return { latitude: null, longitude: null };

  const json = await res.json();

  return transformUserCoordinatesJson(json);
}

export default fetchApiGeolocationFallback;
