import { ReactNode } from 'react';

interface Props {
  title: string;
  subtitle: string;
  subtitleComponent?: ReactNode;
}

export function AccountPageHeader({ title, subtitle, subtitleComponent }: Props) {
  return (
    <div className="p-3" data-testid="accountPageHeader">
      <h2 className="mb-4 font-bold text-2xl">{title}</h2>
      {subtitleComponent ? subtitleComponent : <div>{subtitle}</div>}
    </div>
  );
}

export default AccountPageHeader;
