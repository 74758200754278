import { useEffect, useState } from 'react';
import useSession from './useSession';
import customerApi from 'api/customer';

export function useSavings() {
  const [savings, setSavings] = useState<number | null>(null);
  const { session } = useSession();

  useEffect(() => {
    if (!session) return;

    customerApi.fetchSavings(session?.token).then(setSavings).catch();
  }, [session]);

  return {
    savings,
  };
}

export default useSavings;
